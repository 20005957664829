export default function() {
  // Check if we should show all footnotes
  const showAllFootnotes = document.querySelector('#show-all-footnotes').value === 'true';
  
  // Setup click event listeners for all footnotes
  for (let footNote of document.querySelectorAll('.js-foot-note')) {
    let id = +footNote.id.match(/\d+/)[0];
    footNote.addEventListener('click', () => toggleFootNote(id, footNote.classList[0]));
    
    // If show all footnotes is enabled, show them by default
    if (showAllFootnotes && footNote.id.startsWith('footnote-text-')) {
      const triggerId = `footnote-trigger-${id}`;
      const triggerElement = document.querySelector(`#${triggerId}`);
      if (triggerElement) {
        const baseClass = triggerElement.classList[0];
        const textClass = footNote.classList[0];
        triggerElement.classList.add(`${baseClass}_is-clicked`);
        footNote.classList.add(`${textClass}_is-clicked`);
      }
    }
  }
}

function toggleFootNote(id, base) {
  let trigger = document.querySelector(`#footnote-trigger-${id}`);
  let text = document.querySelector(`#footnote-text-${id}`);
  let end = base.substring(base.length - 4);
  let rest = base.slice(0, base.length - 5);

  if (end === 'text') {
    trigger.classList.toggle(`${rest}_is-clicked`);
    text.classList.toggle(`${base}_is-clicked`);
  } else {
    trigger.classList.toggle(`${base}_is-clicked`);
    text.classList.toggle(`${base}-text_is-clicked`);
  }
}
